import { loadStripe, Stripe } from "@stripe/stripe-js";

let stripePromise: Promise<Stripe | null>;

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51NIICJKqaLcdpMYsv52OkWXa3muArWqDIacqi15XhfdW09GBaXwnBOn549OdxrkaeCMLnABsGald9U2sLSs5202X000NGf6jYX",
    );
  }

  return stripePromise;
};
